import React, { FC, useContext, useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { BusinessContext } from '@src/contexts/BusinessContext'
import api, { cancelToken } from '@src/utils/api'
import { useLocation } from 'react-router'

type NabooFeatureFlagKeys =
  | 'cms_custom_poster'
  | 'cms_livestream_agora'
  | 'cms_ig_importer'
  | 'cms_tiktok_link_importer'
  | 'cms_ig_hashtag_importer'
  | 'cms_ig_link_importer'
  | 'cms_livestream_custom_poster'
  | 'cms_bulk_upload_video'
  | 'cms_custom_protocol'
  | 'cms_livestream_media_gateway'
  | 'cms_ava'
  | 'cms_livestream_ai_content_moderation'
  | 'cms_scheduled_showroom'
  | 'cms_livestream_horizontal'
  | 'cms_video_horizontal'
  | 'cms_edit_in_fw_studio'
  | 'cms_recurrent_showroom'
  | 'cms_sigma_embed'
  | 'cms_smart_button'
  | 'cms_hide_store_for_member_lite'
  | 'cms_microsite_pages'
  | 'one_to_one_lead_capture'
  | 'one_to_one_conversation_intent'
  | 'one_to_one_scheduling_setting'
  | 'one_to_one_offline_visitors'
  | 'one_to_one_widget_description'
  | 'one_to_one_qualify_visitor'
  | 'one_to_one_primary_color'
  | 'cms_oauth_app_fwstudio'
  | 'cms_oauth_app_embed_instructions'
  | 'cms_oauth_app_sidebar_nav'
  | 'cms_dcs_config'
  | 'cms_business_webhooks'
  | 'cms_interactive_video'
  | 'cms_maintenance_mode'
  | 'cms_livestream_hq_simulcast'
  | 'cms_video_2_live'
  | 'cms_livestream_publisher_assistant'
  | 'cms_branding_settings_font'
  | 'cms_flash_deal' // TODO: confirm sunset
  | 'cms_ava_avatar_creation'
  | 'cms_show_product_detail'
  | 'cms_shop_minis'
  | 'cms_shop_minis_v2'
  | 'cms_showroom_fusion_phase_1'
  | 'cms_showroom_fusion_phase_2'
  | 'cms_showroom_fusion_phase_3'
  | 'cms_privacy_policy'
  | 'cms_showroom_entrance'
  | 'cms_products_tab'
  | 'cms_consent_management'
  | 'cms_source_importer'
  | 'cms_ig_crawler'
  | 'cms_tiktok_crawler'
  | 'cms_livestream_automation'
  | 'cms_google_drive_importer'
  | 'cms_youtube_importer'
  | 'cms_link_interactions'
  | 'cms_ava_creation_v2'
  | 'cms_notification_center'
  | 'cms_livestream_free_trial'
  | 'cms_ava_detail_v2'

export type NabooFeatureFlag = {
  [key in NabooFeatureFlagKeys]?: boolean
}

export const NabooFFContext = React.createContext<NabooFeatureFlag>(
  {} as NabooFeatureFlag
)

export const NabooFFContextProvider: FC = ({ children }) => {
  const businessId = useContext(BusinessContext)
  const user = useAppSelector((state) => state.profile.user)
  const oauthApp = useAppSelector((state) => state.user.oauthApp)
  const { pathname } = useLocation()

  const [nabooFF, setNabooFF] = useState<NabooFeatureFlag>({})

  useEffect(() => {
    const params = {
      user: user.encoded_id,
      business: businessId
    }

    // channel
    const channelRegex = /\/channel\/([^/]+)/
    const channelMatch = pathname.match(channelRegex)
    if (channelMatch && channelMatch[1]) {
      params['channel'] = channelMatch[1]
    }

    // chat channel
    const regex = /\/chat_channel\/([^/]+)/
    const match = pathname.match(regex)
    if (match && match[1]) {
      params['chat_channel'] = match[1]
    }

    const ct = cancelToken.source()

    const fetchFeatureFlags = async () => {
      // fetch feature flags from Naboo
      const response = await api.get(`/api/feature_flags`, {
        params,
        oaparams: oauthApp
          ? {
              oauth_app: oauthApp
            }
          : undefined,
        cancelToken: ct.token
      })

      return response.data as NabooFeatureFlag
    }
    // fetch feature flags from Naboo
    fetchFeatureFlags()
      .then((flags) => {
        setNabooFF({
          ...flags
        })
      })
      .catch(() => {
        // ignore
      })

    return () => {
      ct.cancel('Cancel')
    }
  }, [businessId, user, oauthApp, pathname])

  return (
    <NabooFFContext.Provider value={nabooFF}>
      {children}
    </NabooFFContext.Provider>
  )
}
