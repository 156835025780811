import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ConsentStatus,
  ImporterSourceFrom
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import {
  ConsentRequestsData,
  getMediaKeyFromConsentRequestsMedia
} from '@src/redux/consentRequests'
import { IMPORTER_PROXY_URL } from '@src/utils/api'
import {
  defaultApifyDataSetIdExpiresIn,
  getDataset,
  isDateSetExpired,
  runInstagramHashtagCrawler,
  runInstagramUsernameCrawler,
  saveDataset
} from '@src/utils/apify'
import { fetchConsentRequests } from '@src/utils/consentRequestUtils'
import { S3Result } from '@src/utils/s3'
import { Dispatch } from 'redux'

export type InstagramCrawlerItem = {
  error?: string
  id?: string
  childPosts?: InstagramCrawlerItem[]
  videoUrl?: string
  displayUrl?: string
  caption?: string
  timestamp?: string
  ownerFullName?: string
  ownerUsername?: string
  likesCount?: number
  type?: string
  hashtags?: string[]
  url?: string
  consentStatus?: string
  consentId?: string
  signature?: S3Result
}

type IProps = {
  hashtag: string
  hashtagMediaList: InstagramCrawlerItem[]
  username: string
  usernameMediaList: InstagramCrawlerItem[]
}

const initialState: IProps = {
  hashtag: undefined,
  hashtagMediaList: [],
  username: undefined,
  usernameMediaList: []
}

const slice = createSlice({
  name: 'igCrawlerImporter',
  initialState: initialState,
  reducers: {
    fetchInstagramHashtagMediaSuccess(state, action) {
      const { items, hashtag, datasetId } = action.payload
      const date = new Date()
      state.hashtag = hashtag
      state.hashtagMediaList = items
      if (datasetId) {
        saveDataset(
          {
            id: datasetId,
            type: 'hashtag',
            value: hashtag,
            expiresIn: date.getTime() + defaultApifyDataSetIdExpiresIn
          },
          'instagram'
        )
      }
    },
    fetchInstagramUsernameMediaSuccess(state, action) {
      const { items, username, datasetId } = action.payload
      const date = new Date()
      state.username = username
      state.usernameMediaList = items
      if (datasetId) {
        saveDataset(
          {
            id: datasetId,
            type: 'username',
            value: username,
            expiresIn: date.getTime() + defaultApifyDataSetIdExpiresIn
          },
          'instagram'
        )
      }
    }
  }
})

export default slice.reducer

export const {
  fetchInstagramHashtagMediaSuccess,
  fetchInstagramUsernameMediaSuccess
} = slice.actions

const getDatasetByHashtag = (hashtag: string) => {
  const dataset = getDataset(
    {
      type: 'hashtag',
      value: hashtag
    },
    'instagram'
  )
  if (!isDateSetExpired(dataset)) {
    return dataset
  }

  return undefined
}

const getDatasetByUsername = (username: string) => {
  const dataset = getDataset(
    {
      type: 'username',
      value: username
    },
    'instagram'
  )
  if (!isDateSetExpired(dataset)) {
    return dataset
  }

  return undefined
}

const splitArrayIntoChunks = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }

  return result
}

const fetchConsentManagement = async (
  businessId: string,
  instagramCrawlerItems: InstagramCrawlerItem[]
): Promise<InstagramCrawlerItem[]> => {
  let newInstagramItems = instagramCrawlerItems
  // fetch consent management
  if (businessId.length) {
    const ids = newInstagramItems.map((item) => item.id)
    const chunks = splitArrayIntoChunks(ids, 20)
    let consentResultList: ConsentRequestsData[] = []
    for (const chunk of chunks) {
      const data = await fetchConsentRequests({
        businessId,
        mediaSource: 'instagram',
        mediaExternalIds: chunk
      })
      consentResultList = consentResultList.concat(data?.entries || [])
    }
    if (consentResultList.length > 0) {
      newInstagramItems = newInstagramItems.map((item) => {
        const exist = consentResultList?.find((consentResult) => {
          return `${consentResult?.media?.external_id}` === `${item.id}`
        })
        const existMedia = exist?.media

        return {
          ...item,
          consentStatus: exist?.status,
          consentId: exist?.id,
          signature: getMediaKeyFromConsentRequestsMedia(existMedia),
          childPosts: item?.childPosts?.map((childPost) => {
            const find = existMedia?.children?.data?.find((child) => {
              return `${child.external_id}` === `${childPost.id}`
            })

            return {
              ...childPost,
              signature: getMediaKeyFromConsentRequestsMedia(find)
            }
          })
        }
      })
    }
  }

  return newInstagramItems
}

const fetchInstagramHashtagMediaRequest = createAction(
  'igCrawlerImporter/fetchInstagramHashtagMediaRequest'
)
const fetchInstagramHashtagMediaFailure = createAction(
  'igCrawlerImporter/fetchInstagramHashtagMediaFailure'
)

export function fetchInstagramHashtagMedia(params: {
  hashtag: string
  businessId?: string
  needConsent?: boolean
}) {
  return async (dispatch: Dispatch): Promise<InstagramCrawlerItem[]> => {
    try {
      dispatch(fetchInstagramHashtagMediaRequest())
      const { businessId, hashtag, needConsent } = params

      const dataset = getDatasetByHashtag(hashtag)

      const apifyOutput = await runInstagramHashtagCrawler(hashtag, dataset?.id)

      let newInstagramItems =
        apifyOutput?.items?.filter((item) => {
          return !!item?.id
        }) || []

      if (needConsent) {
        newInstagramItems = await fetchConsentManagement(
          businessId,
          newInstagramItems
        )
      }

      if (apifyOutput.datasetId) {
        dispatch(
          fetchInstagramHashtagMediaSuccess({
            items: newInstagramItems,
            hashtag,
            datasetId:
              apifyOutput.datasetId === dataset?.id
                ? undefined
                : apifyOutput.datasetId
          })
        )

        return newInstagramItems
      } else {
        dispatch(fetchInstagramHashtagMediaFailure())

        return []
      }
    } catch (error) {
      dispatch(fetchInstagramHashtagMediaFailure())

      return error
    }
  }
}

const fetchInstagramUsernameMediaRequest = createAction(
  'igCrawlerImporter/fetchInstagramUsernameMediaRequest'
)
const fetchInstagramUsernameMediaFailure = createAction(
  'igCrawlerImporter/fetchInstagramUsernameMediaFailure'
)

export function fetchInstagramUsernameMedia(params: {
  username: string
  businessId?: string
  needConsent?: boolean
}) {
  return async (dispatch: Dispatch): Promise<InstagramCrawlerItem[]> => {
    try {
      dispatch(fetchInstagramUsernameMediaRequest())
      const { username, businessId, needConsent } = params

      const dataset = getDatasetByUsername(username)

      const apifyOutput = await runInstagramUsernameCrawler(
        username,
        dataset?.id
      )

      let newInstagramItems =
        apifyOutput?.items?.filter((item) => {
          return !!item?.id
        }) || []

      if (needConsent) {
        newInstagramItems = await fetchConsentManagement(
          businessId,
          newInstagramItems
        )
      }

      if (apifyOutput.datasetId) {
        dispatch(
          fetchInstagramUsernameMediaSuccess({
            items: newInstagramItems,
            username,
            datasetId:
              apifyOutput.datasetId === dataset?.id
                ? undefined
                : apifyOutput.datasetId
          })
        )

        return newInstagramItems
      } else {
        dispatch(fetchInstagramUsernameMediaFailure())

        return []
      }
    } catch (error) {
      dispatch(fetchInstagramUsernameMediaFailure())

      return error
    }
  }
}

export const convertIgCrawlerItemList2MediaList = (
  list?: InstagramCrawlerItem[]
): BatchImporterMedia[] => {
  const newList = list?.filter((item) => {
    return !item.error && !!item?.id && !!item?.type
  })

  const newMediaItems = []

  newList?.forEach((item) => {
    const displayUrl = `${IMPORTER_PROXY_URL}/apify/ig-picture?url=${encodeURIComponent(
      item.displayUrl
    )}`

    let mediaItem: BatchImporterMedia = {
      id: item.id,
      caption: item.caption,
      timestamp: item?.timestamp,
      displayName: item?.ownerFullName,
      username: item?.ownerUsername,
      likeCount: item?.likesCount,
      mediaType: item.type?.toUpperCase() as BatchImporterMediaType,
      thumbnailUrl: displayUrl,
      mediaUrl: item.videoUrl || displayUrl,
      sourceUrl: item.url,
      hashtags: item.hashtags,
      sourceFrom: ImporterSourceFrom.Instagram,
      consentStatus: item?.consentStatus as ConsentStatus,
      consentId: item?.consentId,
      signature: item?.signature
    }

    const childPosts = item.childPosts || []
    if (childPosts.length) {
      const mediaItemChildren = convertIgCrawlerItemList2MediaList(childPosts)

      mediaItem = {
        ...mediaItem,
        mediaType: BatchImporterMediaType.CAROUSEL_ALBUM,
        children: mediaItemChildren
      }
    }

    newMediaItems.push(mediaItem)
  })

  return newMediaItems
}
