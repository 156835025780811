import React from 'react'

import { Box } from '@src/components/EmotionLayout'
import { SIGMA_INSIGHTS } from '@src/constants'
import EmbedIframe from '@src/pages/channel/ChannelInsights/AnalyticEmbed/EmbedIframe'
import { useParams } from 'react-router-dom'

const SigmaLivestreamInsights: React.FC = () => {
  const { livestreamId } = useParams()

  return (
    <Box flex={1} height="100%">
      <EmbedIframe level={SIGMA_INSIGHTS.VIDEO_LIVESTREAM} id={livestreamId} />
    </Box>
  )
}

export default SigmaLivestreamInsights
