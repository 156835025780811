import React, { useEffect } from 'react'

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Box, Flex } from '@src/components/EmotionLayout'
import FWButton from '@src/components/FWButton/FWButton'
import { isXlScreen } from '@src/hooks/useMediaQuery'
import theme from '@src/styles/theme'

export type Props = {
  borderStyle?: string | null
  buttonColor?: string | null
  isFullSize?: boolean
  fontFamily?: string | null
  buttonFontColor?: string | null
  label?: string
}

export const CardDisplay: React.FC<Props> = ({
  buttonFontColor,
  buttonColor,
  fontFamily,
  borderStyle,
  isFullSize,
  children,
  label
}): JSX.Element => {
  const [showCard, setShowCard] = React.useState(false)
  const isLaptop = isXlScreen()

  const toggleModal = () => {
    setShowCard(!showCard)
  }

  useEffect(() => {
    setShowCard(isLaptop)
  }, [isLaptop])

  const abs = !isLaptop
    ? {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '2'
      }
    : {}

  return (
    <Box minWidth="fit-content">
      {!isLaptop && (
        <Box {...abs} left="unset" bottom="unset" margin="large2" zIndex="3">
          <FWButton onClick={toggleModal}>
            {!showCard ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </FWButton>
        </Box>
      )}
      <Flex {...abs} display={!showCard && 'none'}>
        <Box {...abs} bg="rgba(0,0,0,0.3)" display={isLaptop && 'none'} />
        <Box
          width="185px"
          minWidth="185px"
          height="350px"
          borderRadius={borderStyle ? '0' : '10px'}
          backgroundImage={`url("/placeholder.jpg")`}
          bg="#F8F8F8"
          backgroundSize="cover"
          position="relative"
          zIndex="2"
          border="2px solid #f0f0f0"
        >
          <Box
            bg={buttonColor || theme.primary}
            borderRadius={borderStyle ? '2px' : '30px'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="12px"
            height="26px"
            fontFamily={fontFamily || 'Avenir Next'}
            p="0 14px"
            color={buttonFontColor || '#FFFFFF'}
            position="absolute"
            userSelect="none"
            bottom="5%"
            right="7%"
            left={isFullSize ? '7%' : 'unset'}
          >
            {label}
          </Box>
        </Box>
      </Flex>
      <Box mt={isLaptop && 'xsmall'}>{children}</Box>
    </Box>
  )
}

export default CardDisplay
