import React from 'react'
import { useEffect, useRef, useState } from 'react'
import type { FC, PropsWithChildren } from 'react'

import css from '@emotion/css'
import { useVisibilityObserver } from '@src/hooks/useVisibilityObserver'

type Props = {
  trackingFn?: () => void
}
export const ImpressionContainer: FC<PropsWithChildren<Props>> = ({
  trackingFn,
  children
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const elementRef = useRef(null)
  const trackedRef = useRef(false)
  useVisibilityObserver({
    target: elementRef.current,
    onChange: () => setIsVisible(true)
  })
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isVisible && !trackedRef.current) {
        trackingFn()
        trackedRef.current = true
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [trackingFn, isVisible])

  return (
    <div
      css={css`
        min-width: 1px;
        min-height: 1px;
      `}
      ref={elementRef}
    >
      {children}
    </div>
  )
}
