import { SIGMA_INSIGHTS } from '@src/constants'
import { TRACKING_EVENTS } from '@src/utils/tracking'

export const trackingMap = {
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_OVERVIEW]:
    TRACKING_EVENTS.BUSINESS_INSIGHT_V3_IMPRESSION,
  [SIGMA_INSIGHTS.AVA_BUSINESS]:
    TRACKING_EVENTS.BUSINESS_AVA_BUSINESS_DASHBOARD_IMPRESSION,
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_LIVESTREAM]:
    TRACKING_EVENTS.BUSINESS_INSIGHT_V3_IMPRESSION,
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_SHORT_VIDEO]:
    TRACKING_EVENTS.BUSINESS_INSIGHT_V3_IMPRESSION,
  [SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO]:
    TRACKING_EVENTS.BUSINESS_SHORT_VIDEO_INSIGHT_V3_IMPRESSION,
  [SIGMA_INSIGHTS.VIDEO_LIVESTREAM]:
    TRACKING_EVENTS.BUSINESS_LIVE_STREAM_INSIGHT_V3_IMPRESSION
}

export const trackingSubTabMap = {
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_OVERVIEW]:
    SIGMA_INSIGHTS.VIDEO_CHANNEL_OVERVIEW,
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_LIVESTREAM]:
    SIGMA_INSIGHTS.VIDEO_CHANNEL_LIVESTREAM,
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL]:
    SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL,
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_SHORT_VIDEO]:
    SIGMA_INSIGHTS.VIDEO_CHANNEL_SHORT_VIDEO
}
