import { createAction, createSlice } from '@reduxjs/toolkit'
import { removeUndefined } from '@src/utils/sanitize'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  nextPage: Record<string, number> | null
  videoResources: { [key: string]: AvaVideoResource[] }
}

const initialState: IProps = {
  nextPage: null,
  videoResources: {}
}

const slice = createSlice({
  name: 'avaVideoResource',
  initialState: initialState,
  reducers: {
    fetchAvaVideoResourcesSuccess(state, action) {
      const { entries, nextPage, page, domainAssistantId } = action.payload
      state.videoResources[domainAssistantId] = (page
        ? state.videoResources[domainAssistantId]
        : []
      ).concat(entries)
      state.nextPage = nextPage
    },
    fetchAvaVideoResourceSuccess(state, action) {
      const { videoResource, domainAssistantId } = action.payload
      const resources = state.videoResources[domainAssistantId]
      if (resources) {
        const index =
          resources.findIndex((item) => item.id === videoResource.id) ?? -1
        if (index !== -1) {
          state.videoResources[index] = videoResource
        }
      }
    },
    createAvaVideoResourceSuccess(state, action) {
      const { videoResource, domainAssistantId } = action.payload
      state.videoResources[domainAssistantId] = videoResource.concat(
        state.videoResources[domainAssistantId]
      )
    },
    updateAvaVideoResourceSuccess(state, action) {
      const { videoResource, domainAssistantId } = action.payload
      const resources = state.videoResources[domainAssistantId]
      if (resources) {
        const index =
          resources.findIndex((item) => item.id === videoResource.id) ?? -1
        if (index !== -1) {
          state.videoResources[index] = videoResource
        }
      }
    },
    deleteAvaVideoResourceSuccess(state, action) {
      const { videoResourceId, domainAssistantId } = action.payload
      const resources = state.videoResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === videoResourceId)
        if (index !== -1) {
          state.videoResources[domainAssistantId] = state.videoResources[
            domainAssistantId
          ].slice(index, index + 1)
        }
      }
    }
  }
})

export default slice.reducer

export const {
  fetchAvaVideoResourcesSuccess,
  fetchAvaVideoResourceSuccess,
  createAvaVideoResourceSuccess,
  updateAvaVideoResourceSuccess,
  deleteAvaVideoResourceSuccess
} = slice.actions

export async function fetchAvaVideoResources(
  businessId: string,
  domainAssistantId: string,
  beforeId,
  pageSize = 10
): Promise<any> {
  try {
    const response = await api.get(
      `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/video_resources`,
      {
        params: {
          before_id: beforeId,
          page_size: pageSize
        }
      }
    )

    return response
  } catch (error) {
    return error
  }
}

const fetchAvaVideoResourceRequest = createAction(
  'avaVideoResource/fetchAvaVideoResourceRequest'
)
const fetchAvaVideoResourceFailure = createAction(
  'avaVideoResource/fetchAvaVideoResourceFailure'
)

export function fetchAvaVideoResource(
  businessId: string,
  domainAssistantId: string,
  videoResourceId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaVideoResourceRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/video_resources/${videoResourceId}`
      )
      dispatch(
        fetchAvaVideoResourceSuccess({
          videoResource: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaVideoResourceFailure())

      return error
    }
  }
}

const createAvaVideoResourceRequest = createAction(
  'avaVideoResource/createAvaVideoResourceRequest'
)
const createAvaVideoResourceFailure = createAction(
  'avaVideoResource/createAvaVideoResourceFailure'
)

type ResourceType =
  | 'resource_type'
  | 'manual'
  | 'generated'
  | 'one-to-one'
  | 'live_stream'

type AvaVideoResourceRequest = {
  description?: string
  duration?: number
  key?: string
  // The server will replace the sound track of origin video, if this is true
  replaceSoundtrack?: boolean
  resourceType: ResourceType
  transcript?: string
  title?: string
  // Used for ava integration into one to one functionality
  videoCallId?: string
  videoId?: string
  product_id?: string
}

export function createAvaVideoResource(
  businessId: string,
  domainAssistantId: string,
  data: AvaVideoResourceRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createAvaVideoResourceRequest())
      const response = await api.post(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/video_resources`,
        {
          ...sanitizeVideoResourceParams(data)
        }
      )
      dispatch(
        createAvaVideoResourceSuccess({
          videoResource: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(createAvaVideoResourceFailure())

      return error
    }
  }
}

const updateAvaVideoResourceRequest = createAction(
  'avaVideoResource/updateAvaVideoResourceRequest'
)
const updateAvaVideoResourceFailure = createAction(
  'avaVideoResource/updateAvaVideoResourceFailure'
)

export function updateAvaVideoResource(
  businessId: string,
  domainAssistantId: string,
  videoResourceId: string,
  data: AvaVideoResourceRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateAvaVideoResourceRequest())
      const response = await api.put(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/video_resources/${videoResourceId}`,
        {
          ...sanitizeVideoResourceParams(data)
        }
      )
      dispatch(
        updateAvaVideoResourceSuccess({
          videoResource: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(updateAvaVideoResourceFailure())

      return error
    }
  }
}

const deleteAvaVideoResourceRequest = createAction(
  'avaVideoResource/deleteAvaVideoResourceRequest'
)
const deleteAvaVideoResourceFailure = createAction(
  'avaVideoResource/deleteAvaVideoResourceFailure'
)

export function deleteAvaVideoResource(
  businessId: string,
  domainAssistantId: string,
  videoResourceId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteAvaVideoResourceRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/video_resources/${videoResourceId}`
      )
      dispatch(
        updateAvaVideoResourceSuccess({
          domainAssistantId,
          videoResourceId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteAvaVideoResourceFailure())

      return error
    }
  }
}

const sanitizeVideoResourceParams = (data: AvaVideoResourceRequest) => {
  const sanitized = {
    description: data.description,
    duration: data.duration,
    key: data.key,
    replace_soundtrack: data.replaceSoundtrack,
    resource_type: data.resourceType,
    transcript: data.transcript,
    title: data.title,
    video_call_id: data.videoCallId,
    video_id: data.videoId,
    product_id: data.product_id
  }

  return removeUndefined(sanitized)
}
