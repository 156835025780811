import React from 'react'

import { Box } from '@src/components/EmotionLayout'
import { SIGMA_INSIGHTS } from '@src/constants'
import usePixelAmpTracking from '@src/hooks/usePixelAmpTracking'
import { useParams } from 'react-router-dom'

import EmbedIframe from '../ChannelInsights/AnalyticEmbed/EmbedIframe'

const SigmaVideoInsights: React.FC = () => {
  usePixelAmpTracking()
  const { videoId } = useParams()

  return (
    <Box height="100%" flex="1">
      <EmbedIframe level={SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO} id={videoId} />
    </Box>
  )
}

export default SigmaVideoInsights
