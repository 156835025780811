import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/browser'
import { Box } from '@src/components/EmotionLayout'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
`

const Title = styled.h3`
  color: #333;
  margin-bottom: 20px;
`

const RetryButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`

interface FallbackProps {
  error?: Error
}

const FallbackComponent: React.FC<FallbackProps> = ({ error }) => {
  const { t } = useTranslation()
  const [isErrorExpanded, setIsErrorExpanded] = useState(false)
  const [displayError, setDisplayError] = useState<Error | null>(null)

  const handleRetry = () => {
    window.location.reload()
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error)

    if (error?.message?.includes('Loading chunk')) {
      const retryCount = Number(
        sessionStorage.getItem('chunkLoadRetries') || '0'
      )
      if (retryCount < 2) {
        sessionStorage.setItem('chunkLoadRetries', String(retryCount + 1))
        window.location.reload()
      } else {
        sessionStorage.removeItem('chunkLoadRetries')
      }
    } else {
      setDisplayError(error)
      Sentry.captureException(error)
    }
  }, [error])

  if (!displayError) return null

  return (
    <Container>
      <Title>
        {t('Oops! Something went wrong, please try to refresh the page')}
      </Title>
      {displayError && (
        <>
          <Box
            cursor="pointer"
            mb="20px"
            color="#666"
            onClick={() => setIsErrorExpanded(true)}
          >
            {t('Click to see error details')}
          </Box>
          <Modal
            width={960}
            title={t('Error Details')}
            open={isErrorExpanded}
            onCancel={() => setIsErrorExpanded(false)}
            footer={null}
          >
            <pre
              style={{
                fontSize: '14px',
                color: '#666',
                margin: '0',
                padding: '15px',
                backgroundColor: '#f8f8f8',
                borderRadius: '4px',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                overflowX: 'auto'
              }}
            >
              {`${displayError.message}\n${displayError.stack}`}
            </pre>
          </Modal>
        </>
      )}
      <RetryButton onClick={handleRetry}>{t('Retry')}</RetryButton>
    </Container>
  )
}

export default FallbackComponent
