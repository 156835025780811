import { useLayoutEffect, useRef } from 'react'

type VisibilityObserverProps = {
  target?: React.RefObject<HTMLElement>
  threshold?: number
  onChange: () => void
}
export const useVisibilityObserver = ({
  target,
  threshold = 0.5,
  onChange
}: VisibilityObserverProps): any => {
  const observeRef = useRef(null)
  useLayoutEffect(() => {
    if (!target) return
    observeRef.current?.disconnect()
    const options = {
      threshold: threshold
    }
    const callback = (entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        onChange()
      }
    }
    observeRef.current = new IntersectionObserver(callback, options)
    observeRef.current.observe(target)

    return () => {
      observeRef.current?.disconnect()
      observeRef.current = null
    }
  }, [onChange, target, threshold])

  return target
}
