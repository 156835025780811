import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const OTO = loadable(() => import('../components/Main'))

export const OneToOneRoutes = (
  <Route
    path="/business/:businessId/chat_channel/:chatChannelId/*"
    element={<OTO />}
  />
)
